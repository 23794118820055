const jQuery = require('jquery');

window.$ = window.jQuery = jQuery;

require('corejs-typeahead');
window.Bloodhound = require('corejs-typeahead/dist/bloodhound');

require('./bootstrap-tagsinput');

window.Vapor = require('laravel-vapor');

